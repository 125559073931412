import logoDark from './images/bmBud-darkLogo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logoDark} className="App-logo" alt="logo" />
          <h1>Personlig service, flexibel leverans. Varje gång!</h1>
          <br/>
          <p>
            Hemsidan är under konstruktion.
            <br/>
            Kontakta oss för offert och bokning!
          </p> 
          <div className="App-contact">
            <span className="material-symbols-outlined green">call</span>            
            <a className="App-link" href="tel:+46703302300">070-330 23 00</a> 
          </div>
          <div className="App-contact">
            <span className="material-symbols-outlined green">mail</span>            
            <a className='App-link' href="mailto: kontakt@bmbud.se">kontakt@bmbud.se</a>
          </div> 
      </header>
    </div>
  );
}

export default App;
